<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_19.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da praça situada à frente da Igreja Matriz, utilizada como
            estacionamento. Fonte: ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Situação vivenciada pela comunidade após o rompimento da barragem"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Os caminhões das mineradoras transitam intensamente pela
                comunidade e utilizam o Largo da Matriz como estacionamento
                praticamente o dia todo. Somada ao posicionamento da sinalização
                do trânsito e da fiação de iluminação pública, a presença
                constante desses veículos de grande porte obstruiu a paisagem do
                conjunto arquitetônico e do antigo chafariz localizado à frente
                da igreja.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_20.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do entorno à frente da Igreja Matriz. Fonte:
            ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Em relação à prática do turismo, o fluxo de visitantes diminuiu
                significativamente. Parte dos motivos é a falta de acesso, já
                que com o rompimento da barragem foram causados danos à ponte
                que ligava Bento Rodrigues a Camargos, portanto a Santa Rita
                Durão, Catas Altas e Santa Bárbara, trajeto que fazia parte do
                roteiro da Estrada Real. Em função do desvio nas proximidades da
                Fazenda da Palha, localizada antes da entrada para Camargos, os
                ciclistas e caminhantes não passam mais por Bento, como
                acontecia até a ocorrência do desastre–crime.
              </p>
              <p>
                Diante disso, a economia local foi bastante afetada, sendo que
                pousadas e restaurantes se mantiveram fechados por mais de um
                ano em razão da ausência de fregueses. Outros não tiveram
                condição de retomar as atividades e fecharam. A afetação da
                atividade turística ocorreu também pela lama de rejeitos ter
                atingido a cachoeira localizada em Camargos, impossibilitando os
                banhos e a pesca no ribeirão próximo que era muito procurado por
                moradores locais e visitantes. A destruição da ponte também
                prejudicou o trânsito de moradores que vinham de localidades
                vizinhas para participarem dos festejos e vice-versa.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Ressalta-se que o distrito de Santa Rita Durão também convive
                com o receio de um novo rompimento da barragem, a exemplo de
                Germano, visto que por todo o núcleo encontram-se placas de
                sinalização de rota de fuga e ponto de encontro.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira</strong> <sup>1</sup>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_21.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Vista de placas de sinalização de ponto de encontro instalada à
            frente da Igreja de Nossa Senhora do Rosário. Fonte:
            ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_22.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Vista de uma placa de sinalização no núcleo urbano com o alerta
            sobre o toque da sirene e a necessidade de serem seguidas as
            indicações da sinalização de rota de fuga. Fonte:
            ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_23.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Placas de sinalização de rota de fuga e ponto de encontro,
            instaladas à frente da Igreja Matriz. . Fonte: ETII/IPHAN/Mariana,
            fevereiro de 2022.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_24.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Placa de sinalização de rota de fuga instalada à frente da Casa com
            rótulas . Fonte: ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
